import {
    Component,
    inject,
    Input,
    ViewChild,
    ViewContainerRef,
    ChangeDetectionStrategy,
    OnInit,
    input,
    output,
    model,
    effect,
    signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';

import { ActivatedRoute } from '@angular/router';

import {
    MobiUiButtonBarComponent,
    MobiUiExpanderComponent,
    MobiUiExpanderComponents,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiPopoverComponent,
    MobiUiPopoverDirective,
} from '@mobi/rwc-ui-components-ng-jslib';

import { mobiIconCopy, mobiIconShare } from '@mobi/rwc-utils-icons-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import {
    TealiumTrackInteractionClickDirective,
    TealiumTrackInteractionDirective,
    TealiumTrackLinkOnsiteDirective,
} from '@mobi/tea-tagmanager-ng-jslib';

import { Content, TealiumTrackingFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ParagraphComponent } from '../paragraph/paragraph.component';
import { getParagraphCss } from '../../../util/get-css-style';

import { BulletListComponent } from '../bullet-list/bullet-list.component';
import { NumberedListComponent } from '../numbered-list/numbered-list.component';

import { Helper } from '../../helper';
import { BoxComponent } from '../box/box.component';
import { TableComponent } from '../table/table.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'oiv-viewer-expander',
    standalone: true,
    imports: [
        ClipboardModule,
        CommonModule,
        MobiUiIconComponent,
        MobiUiExpanderComponents,
        NumberedListComponent,
        BulletListComponent,
        BoxComponent,
        ParagraphComponent,
        MobiUiButtonBarComponent,
        MobiUiPopoverDirective,
        MobiUiPopoverComponent,
        TranslatePipe,
        TealiumTrackLinkOnsiteDirective,
        TealiumTrackInteractionClickDirective,
        TealiumTrackInteractionDirective,
        TableComponent,
    ],
    providers: [Clipboard],
    templateUrl: './expander.component.html',
    styleUrl: './expander.component.scss',
})
export class ExpanderComponent implements OnInit {
    helper = inject(Helper);
    #route = inject(ActivatedRoute);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #clipboard = inject(Clipboard);
    #mobiUIIconsRegistry = inject(MobiUiIconsRegistry);
    isFirstLoad = signal(true);
    initFragment = signal('');

    @Input() set expanderInput(expanderInput: Content | null) {
        if (expanderInput) {
            this.expanderContents = [...(expanderInput.content as Content[])];
            this.expanderContents
                .filter((content: Content) => content.type === OIV_TYPE.SECTION_TITLE)
                .forEach((content: Content) => {
                    this.title = this.helper.parseWithBoldItalicXmlTags(content.content as string);
                    this.orderNumber = content.attributeOrder;
                });
            this.eId = expanderInput.eId;
        }
    }
    expanderLevel = input(0);
    expandedAllState = model({ expanded: false });
    expandedSingleState = model(false);
    shareEvent = output();
    copyEvent = output();
    openCloseEvent = output<boolean>();
    @ViewChild('expanderContent', { read: ViewContainerRef, static: true }) ref!: ViewContainerRef;
    @ViewChild('expander', { static: true }) mobiExpander!: MobiUiExpanderComponent;

    title = '';
    orderNumber: string | undefined;
    public eId = '';
    readonly OIV_TYPE = OIV_TYPE;
    expanderContents: Content[] = [];
    getParagraphCss = getParagraphCss;

    constructor() {
        this.setExpandedStateAllIfChanged();
        this.openExpanderWhenOpenNewPageWithFragment();
    }

    ngOnInit(): void {
        this.#mobiUIIconsRegistry.registerIcons([mobiIconShare, mobiIconCopy]);
        this.navigateToFragment();
    }

    setExpandedStateAllIfChanged(): void {
        effect(
            () => {
                this.expandedSingleState.set(this.expandedAllState().expanded);
            },
            { allowSignalWrites: true },
        );
    }

    openExpanderWhenOpenNewPageWithFragment(): void {
        effect(
            () => {
                if (this.isFirstLoad() && this.initFragment()) {
                    this.openExpander();
                }
                this.isFirstLoad.set(false);
            },
            { allowSignalWrites: true },
        );
    }

    navigateToFragment(): void {
        this.#route.fragment.subscribe(fragment => {
            // Create a container root support for searchContentByEid function
            const rootContainer: Content = {
                eId: 'avb',
                type: OIV_TYPE.CONTAINER,
                content: this.expanderContents,
            };
            if (fragment && (fragment === this.eId || this.helper.searchContentByEid(fragment, rootContainer))) {
                this.openExpander();
                this.initFragment.set(fragment);
            }
        });
    }

    openExpander(): void {
        this.expandedSingleState.set(true);
    }

    // this function for resolving testing limitation which
    // not allow to access input signal directly
    getExpandedState(): boolean {
        return this.expandedSingleState();
    }

    copyContent(content: HTMLElement) {
        function listener(e: ClipboardEvent) {
            const { clipboardData } = e;
            clipboardData?.setData('text/html', content.innerHTML);
            clipboardData?.setData('text/plain', content.innerHTML);
            e.preventDefault();
        }
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.copyEvent.emit();
        this.#timeout();
    }

    //TODO: upgrade this method When we have further requirements for this method
    share() {
        const url = window.location.href;
        const domain = url.split('#')[0];
        this.#clipboard.copy(domain + `#${this.eId}`);
        this.shareEvent.emit();
        this.#timeout();
    }

    #timeout() {
        setTimeout(() => document.body.click(), 1000);
    }

    trackInteraction(type: 'open' | 'close'): void {
        this.#tealiumTrackingFacade.trackInteractionEvent(
            'toggle expand ' + this.title,
            'collapse - expand',
            this.title,
            type,
        );
    }

    onOpenedOrClosed(type: 'open' | 'close'): void {
        this.trackInteraction(type);
        this.expandedSingleState.set(type === 'open');
    }

    increaseExpanderLevel(): number {
        return this.expanderLevel() + 1;
    }
}
