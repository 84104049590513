import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { TealiumTrackDocumentDownloadDirective, TealiumTrackLinkOffsiteDirective } from '@mobi/tea-tagmanager-ng-jslib';

import { mobiIconArrowUpperRight, mobiIconDownload, mobiIconSignpost } from '@mobi/rwc-utils-icons-jslib';

import { OivCoreSharedFacade, TealiumTrackingFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { NavigationFacade } from '../../data/navigation-facade';
import { OivViewerFacade } from '../../../core/data/facade/oiv-viewer-facade';

@Component({
    standalone: true,
    selector: 'oiv-viewer-give-me-more',
    templateUrl: './give-me-more.component.html',
    styleUrls: ['./give-me-more.component.scss'],
    imports: [
        MobiUiIconComponent,
        TranslatePipe,
        TealiumTrackLinkOffsiteDirective,
        TealiumTrackDocumentDownloadDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiveMeMoreComponent {
    #viewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #navigationFacade = inject(NavigationFacade);
    #destroyRef = inject(DestroyRef);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);

    // Convert observables to signals
    appType = this.#coreSharedFacade.appContext;
    isApprovalMode = this.#coreSharedFacade.isApprovalMode;
    links = this.#coreSharedFacade.links;
    metadata = this.#viewerFacade.avbMetadata;

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconSignpost, mobiIconArrowUpperRight, mobiIconDownload]);
    }

    public downloadPdf() {
        this.#navigationFacade
            .downloadPdf(this.metadata().textReference, this.metadata().language)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(data => {
                const title = `${this.metadata().textReference}_${this.metadata().language}.pdf`;
                const url = window.URL.createObjectURL(data);
                const a = document.createElement('a');
                a.href = url;
                a.download = this.#getPdfName();
                a.click();
                this.#tealiumTrackingFacade.trackDocumentDownloadEvent('pdf', title, url);
            });
    }

    public navigateToPage(page?: string) {
        if (page) window.open(page, '_blank');
    }

    #getPdfName(): string {
        return this.metadata().description
            ? `${this.metadata().description}.pdf`
            : `${this.metadata().textReference}_${this.metadata().language}.pdf`;
    }
}
