import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';

import { Router } from '@angular/router';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import {
    MobiUiMessageItemComponent,
    MobiUiMessageLevel,
    MobiUiMessagesComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { Content, OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { SeparatorComponent } from '../../ui/separator/separator.component';
import { AiFilterComponent } from '../../ui/filter/ai-filter.component';
import { AssistantMessageComponent } from '../../ui/assistant-message/assistant-message.component';
import { UserMessageComponent } from '../../ui/user-message/user-message.component';
import { AiFacade } from '../../data/facade/ai-facade';

import { Conversation, Feedback, Message } from '../../data/model/message.model';

import { Helper } from '../../../../share/helper';

import { SearchResultItem } from '../../../index-search/ui/search-result-item/search-result-item.component';
import { PromptComponent } from '../../ui/prompt/prompt.component';

import { ErrorMessageComponent } from '../../ui/error-message/error-message.component';
import { OivViewerFacade } from '../../../../oiv-viewer/core/data/facade/oiv-viewer-facade';

@Component({
    selector: 'oiv-viewer-conversation',
    standalone: true,
    imports: [
        CommonModule,
        SeparatorComponent,
        TranslatePipe,
        AiFilterComponent,
        AssistantMessageComponent,
        UserMessageComponent,
        PromptComponent,
        MobiUiMessageItemComponent,
        MobiUiMessagesComponent,
        ErrorMessageComponent,
    ],
    templateUrl: './conversation.component.html',
    styleUrl: './conversation.component.scss',
})
export class ConversationComponent {
    #helper = inject(Helper);
    #router = inject(Router);
    #oivViewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #aiFacade = inject(AiFacade);

    conversation = input.required<Conversation>();
    isMissingFilter = input.required<boolean>();

    selectedFilterItem = output<string>();
    suggestionSelected = output<string>();
    filterOpen = output<boolean>();

    chapters = this.#oivViewerFacade.avbChapters;
    feedbackCategories = this.#oivViewerFacade.feedbackCategories;
    isDisplaySuggestion = this.#aiFacade.isDisplaySuggestion;
    prompts = this.#aiFacade.prompts;
    isFeedbackSent = signal(false);
    messages = signal<Message[]>([]);
    chapterTitles = computed(() => this.#helper.getAllChapterTitles(this.chapters()));

    protected MESSAGE_CLOSE_TIMEOUT = 3000;
    protected readonly DateTime = DateTime;

    constructor() {
        effect(
            () => {
                if (this.conversation()) {
                    this.messages.update(() => {
                        return this.conversation().messages;
                    });
                }
            },
            { allowSignalWrites: true },
        );
    }

    handlePromptSelected(suggestion: string) {
        this.suggestionSelected.emit(suggestion);
    }

    handleMessageClose(): void {
        this.isFeedbackSent.set(false);
    }

    handleFilter($event: string) {
        this.selectedFilterItem.emit($event);
    }

    handleSendFeedback(feedback: any) {
        const messageId = feedback.messageId;
        // Need to use combinedId to identify the conversation (that is the id from server)
        const feedbackData = {
            conversationId: this.conversation().combinedId,
            type: feedback.type,
            category: feedback.category,
            detail: feedback.feedBackContent,
            // Assign the feedback into corresponding message
            conversationGeneralData: this.messages().map(message => {
                if (message.id === messageId) {
                    return {
                        ...message,
                        feedback: feedback.feedBackContent,
                        type: feedback.type,
                    };
                } else {
                    return message;
                }
            }),
        } as Feedback;
        this.#aiFacade.sendFeedback(messageId, feedbackData);
        this.isFeedbackSent.set(true);
    }

    getFeedbackData() {
        return {
            message: 'oiv.feedback.send.success',
            level: MobiUiMessageLevel.SUCCESS,
            statusCode: 200,
        };
    }

    renderMessageReference(message: Message): Message {
        return {
            ...message,
            content: this.renderReferenceInMessageContent(message.content ? message.content : ''),
        };
    }

    renderReferenceInMessageContent(message: string): string {
        const regex = /\[eID: ([^\]]+)]/gi; // this regex to detect the inline reference [eId] in the message content
        return message.replace(regex, (match, p1) => {
            const eIds = p1.split(/,\s*eID:\s*/i).map((eid: string) => eid.trim()); // support for detect multiple eIds in a bracket
            return eIds
                .map((eid: string) => {
                    const { numbering, url } = this.handleSingleReference(eid);
                    return `[[${numbering}]](${url})`;
                })
                .join(', ');
        });
    }

    handleSingleReference(eId: string): { url: string; numbering: string } {
        const rootChapter: Content = {
            eId: 'avb',
            type: OIV_TYPE.CHAPTER_TITLE,
            content: this.chapters(),
        };
        const content = this.#helper.searchContentByEid(eId, rootChapter);
        if (content) {
            return {
                url: this.#getUrlPath(content),
                numbering: this.#helper.getNumberingOfContainer(content),
            };
        }
        return { url: '', numbering: '' };
    }

    #getUrlPath(content: Content): string {
        const rootChapter: Content = {
            eId: 'avb',
            type: OIV_TYPE.CHAPTER_TITLE,
            content: this.chapters(),
        };
        const url = this.#helper.getBaseUrl(
            this.#router,
            this.#coreSharedFacade.appContext(),
            this.#coreSharedFacade.isApprovalMode(),
        );
        return this.#helper.getUrlPath(content, rootChapter, url);
    }

    handleReferences(message: Message): SearchResultItem[] {
        const contents = this.getContentsByReferencesEid(message);
        return this.buildReferencesByContents(contents);
    }

    getContentsByReferencesEid(message: Message): Content[] {
        const references = message.references || [];
        // Wrap all the chapters into a root chapter support for searching
        const rootChapter: Content = {
            eId: 'avb',
            type: OIV_TYPE.CHAPTER_TITLE,
            content: this.chapters(),
        };
        return references
            .map(reference => this.#helper.searchContentByEid(reference.eID, rootChapter))
            .filter((item): item is Content => item != null);
    }

    buildReferencesByContents(contents: Content[]): SearchResultItem[] {
        if (!contents || contents.length === 0) {
            return [];
        }
        return contents.map(content => ({
            referenceId: content.eId,
            breadcrumb: this.#helper.buildBreadCrumbByHierarchy(content.hierarchy ? content.hierarchy : []),
            title: this.#helper.buildTitleForReference(content),
            description: '',
            urlPath: this.#getUrlPath(content),
        }));
    }

    handleDropdownOpen(isOpened: boolean) {
        this.filterOpen.emit(isOpened);
    }
}
