import { inject, Injectable } from '@angular/core';
import { RequestOptions } from '@algolia/transporter';
import { from, Observable } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import { SearchClient, SearchIndex } from 'algoliasearch/dist/algoliasearch-lite';

import algoliasearch from 'algoliasearch/lite';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { Record, SearchResult } from '../../model/search-model';
import { SearchParam } from '../../model/search-param';
import { OivViewerFacade } from '../../../../oiv-viewer/core/data/facade/oiv-viewer-facade';

@Injectable({ providedIn: 'root' })
export class AlgoliaService {
    client!: SearchClient;
    index!: SearchIndex;
    #oivViewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);

    source = this.#coreSharedFacade.appContext;

    constructor() {
        this.#oivViewerFacade.getIndex().subscribe(state => {
            this.client = algoliasearch(state.applicationId, state.searchOnlyApiKey);
            this.index = this.client.initIndex(state.indexId);
        });
    }

    performSearch(query: string, requestOption: RequestOptions): Observable<SearchResult> {
        return from(
            this.index.search(query, requestOption).then(({ hits, nbHits, nbPages, queryID }): SearchResult => {
                return { hits: hits as Record[], nbHits, nbPages, queryID };
            }),
        );
    }

    createRequestOption({ page, hitsPerPage }: SearchParam): RequestOptions {
        if (this.source() === 'B2E' && this.checkIfWeAreAllowedToSendUserTokenToAlgolia()) {
            return {
                page,
                hitsPerPage,
                clickAnalytics: true,
                userToken: this.setAndGetAlgoliaUserTokenCookie(),
            };
        }

        return {
            page,
            hitsPerPage,
            clickAnalytics: true,
        };
    }

    getCookieValueOfOneTrustCookie(): string {
        // In OptanonConsent cookie is stored if the user has accepted the cookies
        const cookies = document.cookie.split('; ').filter(cookie => cookie.includes('OptanonConsent'));
        if (cookies.length !== 1 || !cookies[0].includes('groups=')) return '';
        let cookieValue = decodeURIComponent(cookies[0]);
        cookieValue = cookieValue.substring(cookieValue.indexOf('groups=') + 7);
        return cookieValue.includes('&') ? cookieValue.substring(0, cookieValue.indexOf('&')) : cookieValue;
    }

    /*
     *   C0001 = Necessary Cookies
     *   C0002 = Performance Cookies --> Statistics
     *   C0003 = Functional cookies --> Personalization
     *   C0004 = Marketing-Cookies
     */
    checkIfWeAreAllowedToSendUserTokenToAlgolia(): boolean {
        const cookieValue = this.getCookieValueOfOneTrustCookie();
        return cookieValue.includes('C0001:1') && cookieValue.includes('C0002:1') && cookieValue.includes('C0003:1');
    }

    setAndGetAlgoliaUserTokenCookie(): string {
        // check if we are allowed to set the user token
        if (!this.checkIfWeAreAllowedToSendUserTokenToAlgolia()) return '';
        // check if there is already a user token cookie
        const userTokenCookie = document.cookie.split('; ').find(cookie => cookie.includes('AlgoliaUserToken'));
        if (userTokenCookie) return userTokenCookie.split('=')[1];
        const userToken = uuidv4();
        const expires = new Date();
        // expires after 8h
        expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);
        document.cookie = `AlgoliaUserToken=${userToken}; expires=${expires.toUTCString()}; path=/`;
        return userToken;
    }
}
