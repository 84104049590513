import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';
import { mobiIconApplicationWindow, mobiIconFace, mobiIconGoToLink } from '@mobi/rwc-utils-icons-jslib';
import { Links } from '@mobi/oiv-viewer-utils-ng-jslib';

@Component({
    selector: 'oiv-viewer-more-information',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent, TranslatePipe],
    templateUrl: './more-information.component.html',
    styleUrl: './more-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreInformationComponent {
    links = input<Links>();
    items = computed(() => {
        return [
            {
                text: 'oiv.not-found.customer-portal',
                link: this.links()?.notFoundPageCustomerPortal,
                icon: 'application_window',
            },
            {
                text: 'oiv.not-found.contact',
                link: this.links()?.notFoundPageContact,
                icon: 'face',
            },
        ];
    });

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconGoToLink, mobiIconFace, mobiIconApplicationWindow]);
    }
}
