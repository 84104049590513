import { ApplicationConfig } from '@angular/core';
import {
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withRouterConfig,
} from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptorService } from '@mobi/oiv-viewer-ng-jslib';

import { provideB2cOivCore } from '@mobi/oiv-viewer-utils-ng-jslib';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideB2cOivCore(),
        provideRouter(
            appRoutes,
            withRouterConfig({
                onSameUrlNavigation: 'reload',
            }),
            withComponentInputBinding(),
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'top',
            }),
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
    ],
};
