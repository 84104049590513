import { Component, inject, input } from '@angular/core';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { getParagraphCss } from '../../../util/get-css-style';
import { ParagraphComponent } from '../paragraph/paragraph.component';
import { NumberedListComponent } from '../numbered-list/numbered-list.component';
import { BulletListComponent } from '../bullet-list/bullet-list.component';
import { Helper } from '../../helper';

@Component({
    selector: 'oiv-viewer-render-components',
    standalone: true,
    imports: [ParagraphComponent, NumberedListComponent, BulletListComponent],
    templateUrl: './render-content.component.html',
    styleUrl: './render-content.component.scss',
})
export class RenderContentComponent {
    helper = inject(Helper);

    content = input({} as Content);

    returnContentArray(content: Content | string): Content[] {
        return typeof content === 'string' ? [] : (content.content as Content[]);
    }

    protected readonly OIV_TYPE = OIV_TYPE;
    protected readonly getParagraphCss = getParagraphCss;
}
