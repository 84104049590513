import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { shareReplay, skip, withLatestFrom } from 'rxjs';

import { map } from 'rxjs/operators';

import { PushPipe } from '@ngrx/component';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { getRouterSelectors } from '@ngrx/router-store';

import {
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiLoadingIndicatorComponent,
    MobiUiPaginatorPageEvent,
    MobiUiSplitButtonComponent,
    MobiUiSplitButtonGroupComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { LoadingState } from '@mobi/rwc-uxframework-ng';

import { TealiumTrackingService } from '@mobi/tea-tagmanager-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { mobiIconError } from '@mobi/rwc-utils-icons-jslib';

import { OIV_TYPE, XML_TAG } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { Content, OivCoreSharedFacade, OivError } from '@mobi/oiv-viewer-utils-ng-jslib';

import { SEARCH_PAGE_SIZE } from '../../../util/constant/common';
import { SearchParam } from '../model/search-param';
import { SearchBoxComponent } from '../ui/search-box/search-box.component';
import { SearchResultItem } from '../ui/search-result-item/search-result-item.component';
import { SearchResultListComponent } from '../ui/search-result-list/search-result-list.component';
import { Record, SearchResult } from '../model/search-model';
import { OverlayComponent } from '../../../share/components/overlay/overlay.component';
import { IndexSearchComponentStore } from '../data/component-store/index-search.component-store';
import { Helper } from '../../../share/helper';
import { fromOivViewer } from '../../../oiv-viewer/core/data/store/oiv-viewer.selectors';
import { OivViewerFacade } from '../../../oiv-viewer/core/data/facade/oiv-viewer-facade';

import { SUBHEAD_EID, SUBTIT_EID, TIT_EID } from '../model/common.instant';

@Component({
    selector: 'oiv-viewer-index-search',
    standalone: true,
    imports: [
        CommonModule,
        SearchBoxComponent,
        MobiUiIconComponent,
        RouterOutlet,
        MobiUiSplitButtonComponent,
        MobiUiSplitButtonGroupComponent,
        SearchResultListComponent,
        PushPipe,
        MobiUiLoadingIndicatorComponent,
        OverlayComponent,
        TranslatePipe,
    ],
    templateUrl: './index-search.component.html',
    styleUrl: './index-search.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [IndexSearchComponentStore],
})
export class IndexSearchComponent implements OnInit {
    #route = inject(ActivatedRoute);
    #router = inject(Router);
    #store = inject(Store);
    #helper = inject(Helper);

    #indexSearchStore = inject(IndexSearchComponentStore);
    #oivViewerFacade = inject(OivViewerFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #destroyRef = inject(DestroyRef);
    tealiumTrackingService = inject(TealiumTrackingService);

    allResultItems = signal<SearchResultItem[]>([]);
    keySearch = signal<string>('');
    currentPage = signal<number>(0);
    numberHits = signal<number>(0);
    chapters = signal<Content[]>([]);
    loadingIndex$ = this.#oivViewerFacade.getLoadingIndex().pipe(shareReplay(1));
    loadingSearch$ = this.#indexSearchStore.loadingSearch$;

    isLoadingIndex = toSignal(this.loadingIndex$.pipe(map(loadingState => loadingState === LoadingState.LOADING)));
    isLoadingSearch = toSignal(this.loadingSearch$.pipe(map(loadingState => loadingState === LoadingState.LOADING)));
    loadingStatus = this.#store.selectSignal(fromOivViewer.selectLoadingIndex);

    fragment = toSignal(this.#store.select(getRouterSelectors().selectFragment));
    text = signal<string>('');
    page = signal<number>(0);

    protected readonly LoadingState = LoadingState;

    constructor() {
        // TODO: deep link
        inject(MobiUiIconsRegistry).registerIcons([mobiIconError]);
        effect(
            () => {
                this.keySearch.set(this.text() || '');
                const searchParam: SearchParam = {
                    text: this.text(),
                    page: this.page(),
                    hitsPerPage: SEARCH_PAGE_SIZE,
                };
                this.search(searchParam);
            },
            { allowSignalWrites: true },
        );
    }

    ngOnInit(): void {
        this.#subscribeToQueryParams();
        this.#subscribeToChapters();
        this.#subscribeToSearchResults();
        this.#subscribeToText();
        this.#subscribeToPage();
    }

    #subscribeToQueryParams(): void {
        this.#route.queryParams.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(params => {
            const { text = '', page = 0 } = params;
            this.keySearch.set(text || '');
            const searchParam: SearchParam = {
                text,
                page: parseInt(page),
                hitsPerPage: SEARCH_PAGE_SIZE,
            };
            this.search(searchParam);
        });
    }

    #subscribeToChapters(): void {
        this.#store
            .select(fromOivViewer.selectChapters)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(chapters => {
                this.chapters.set(chapters);
            });
    }

    #subscribeToSearchResults(): void {
        this.#indexSearchStore.searchResult$
            .pipe(skip(1), takeUntilDestroyed(this.#destroyRef), withLatestFrom(this.#oivViewerFacade.getIndex()))
            .subscribe(([state, index]) => {
                const { resultItems, numberHits, numberPages, queryID } = this.transformSearchResult(state);
                this.allResultItems.set(resultItems);
                this.numberHits.set(numberHits);
                const trackObj = {
                    tealium_event: 'search_result',
                    search_engine: 'Algolia',
                    page_name: 'Index search',
                    search_keyword: this.keySearch(),
                    search_results: this.numberHits().toString(),
                    search_index: index.indexId,
                    search_query_id: queryID,
                };
                this.tealiumTrackingService.trackPageView(trackObj);
            });
    }

    #subscribeToText(): void {
        this.#indexSearchStore.text$.subscribe(text => {
            this.keySearch.set(text);
        });
    }

    #subscribeToPage(): void {
        this.#indexSearchStore.page$.subscribe(page => {
            this.currentPage.set(page);
        });
    }

    search(searchParam: SearchParam) {
        const { text, page } = searchParam;
        if (!text) {
            this.allResultItems.set([]);
            this.keySearch.set('');
            return;
        }
        this.loadingIndex$.subscribe(loading => {
            if (loading === LoadingState.READY) {
                this.#indexSearchStore.search(searchParam);
            }
        });
    }

    transformSearchResult(data: SearchResult) {
        const resultItems: SearchResultItem[] = [];
        const numberHits: number = data.nbHits;
        const numberPages: number = data.nbPages;
        data.hits.forEach((item: Record) => {
            let breadcrumb = '';
            let title: string | undefined = '';
            let description = '';
            if (item.type === XML_TAG.TITLE) {
                const styleForBreadcrumb = this.#helper.getStyleForBreadcrumb(item.hierarchy[0].numbering);
                title = styleForBreadcrumb + item._highlightResult.content.value;
                breadcrumb = styleForBreadcrumb + item.hierarchy[0].content;
            } else if (item.type.includes(XML_TAG.HEAD) || item.type.includes(XML_TAG.SUBTITLE)) {
                const lastHierarchyItem = item.hierarchy.pop();
                const styleForBreadcrumb = this.#helper.getStyleForBreadcrumb(lastHierarchyItem!.numbering);
                title = styleForBreadcrumb + item._highlightResult.content.value;
                breadcrumb = item.hierarchy
                    .map(
                        hierarchyItem =>
                            this.#helper.getStyleForBreadcrumb(hierarchyItem.numbering) + hierarchyItem.content,
                    )
                    .join(' > ');
                description = '';
            } else {
                const lastHierarchyItem = item.hierarchy.length > 1 ? item.hierarchy.pop() : item.hierarchy[0];
                const styleForBreadcrumb = this.#helper.getStyleForBreadcrumb(lastHierarchyItem!.numbering);
                title = styleForBreadcrumb + lastHierarchyItem!.content;
                breadcrumb = item.hierarchy
                    .map(
                        hierarchyItem =>
                            this.#helper.getStyleForBreadcrumb(hierarchyItem.numbering) + hierarchyItem.content,
                    )
                    .join(' > ');
                description = item._highlightResult.content.value;
            }
            const urlPath = this.getUrlPath(item.objectID);
            const searchResultItem = <SearchResultItem>{
                referenceId: item.objectID,
                breadcrumb: breadcrumb,
                title: title,
                description: description,
                urlPath: urlPath,
            };
            resultItems.push(searchResultItem);
        });

        return { resultItems, numberHits, numberPages, queryID: data.queryID };
    }

    changePageParam($event: MobiUiPaginatorPageEvent) {
        this.page.set($event.pageIndex);
    }

    changeSearchParam(searchParam: SearchParam): void {
        this.text.set(searchParam.text);
        this.page.set(0);
    }

    getUrlPath(referenceId: string): string {
        referenceId = this.filterInvalidEIdSegments(referenceId);
        const rootChapter: Content = {
            eId: 'avb',
            type: OIV_TYPE.CHAPTER_TITLE,
            content: this.chapters(),
        };
        const content = this.#helper.searchContentByEid(referenceId, rootChapter);
        if (!content) {
            throw new OivError('Content not found');
        }

        // the router.url will look like this: /avb/KMU-GEB-08-2024/chapters/...
        // so we need to split it and get 3 first segments to get the correct path: /avb/KMU-GEB-08-2024
        const url = this.#helper.getBaseUrl(
            this.#router,
            this.#coreSharedFacade.appContext(),
            this.#coreSharedFacade.isApprovalMode(),
        );
        return this.#helper.getUrlPath(content, rootChapter, url);
    }

    filterInvalidEIdSegments(referenceId: string) {
        return referenceId
            .split('/')
            .filter(
                segment =>
                    !segment.includes(SUBHEAD_EID) && !segment.includes(SUBTIT_EID) && !segment.includes(TIT_EID),
            )
            .join('/');
    }
}
