{
  "chat_with_avb_failed.generic_error": "Q&A Feature ist nicht verfügbar",
  "chat_with_avb_failed.index_not_found": "Q&A Feature ist nicht verfügbar",
  "chat_with_avb_failed.rate_limit_exceeded": "Q&A Feature ist nicht verfügbar",
  "chat_with_avb_failed.service_request_error": "Q&A Feature ist nicht verfügbar",
  "chat_with_avb_failed.value_error.invalid_language_or_text_reference": "Q&A Feature ist nicht verfügbar",
  "oiv.aiSearch.console.createNewConversation": "Neue Unterhaltung erstellen",
  "oiv.aiSearch.console.firstQuestion": "Neue Frage eingeben",
  "oiv.aiSearch.console.followUpQuestion": "Folgefrage eingeben",
  "oiv.aiSearch.console.sendMessage": "Absenden",
  "oiv.aiSearch.disclaimer": "Fragen werden mit Hilfe von Künstlicher Intelligenz beantwortet. Es besteht keine rechtsverbindliche Gültigkeit. Es dürfen keine personenbezogenen Daten in der Frage eingegeben werden.",
  "oiv.aiSearch.filter.chosenTopic": "Ausgewähltes Kapitel",
  "oiv.aiSearch.filter.selectAllTopics": "Alle Kapitel",
  "oiv.aiSearch.filter.selectTopic": "Kapitel auswählen",
  "oiv.aiSearch.followUpQuestionSeparator": "Folgefrage",
  "oiv.aiSearch.mobiAi": "Mobi-AI",
  "oiv.aiSearch.newConversationSeparator": "Neue Frage",
  "oiv.aiSearch.question": "Frage",
  "oiv.aiSearch.reference": "Quellen",
  "oiv.aiSearch.Sie": "Sie",
  "oiv.aiSearch.suggestion": "Vorschläge",
  "oiv.aiSearch.suggestion.bird": "[Not used anymore] Sind Beschädigungen am Gebäude durch Kot von Vögeln versichert?",
  "oiv.aiSearch.suggestion.blockedWaterPipes": "[Not used anymore] Ist die Rohrreinigung für verstopfte Wasserleitungen versichert?",
  "oiv.aiSearch.suggestion.liability": "[Not used anymore] Bis zu welcher Bausumme besteht Deckung für die Haftpflicht als Bauherr?",
  "oiv.aiSearch.suggestion.lossOfRentalIncome": "[Not used anymore] Ist der Mietausfall durch nicht bezahlte Mieten versichert?",
  "oiv.aiSearch.title": "Frage & Antwort",
  "oiv.app.mobiliar": "die Mobiliar",
  "oiv.app.protekta": "Protekta",
  "oiv.app.title": "Allgemeine Bedingungen",
  "oiv.button.close": "Schliessen",
  "oiv.error.aiSearch.filterIsMissing.description": "Bitte wählen Sie ein Kapitel aus.",
  "oiv.error.aiSearch.filterIsMissing.title": "Kein Kapitel ausgewählt",
  "oiv.error.aiSearch.notAvailable.description": "Bitte versuche Sie es in ein paar Minuten erneut oder kontaktieren Sie den Service-Desk.",
  "oiv.error.aiSearch.notAvailable.title": "Q&A-Funktion ist nicht verfügbar",
  "oiv.error.avbNotFound": "AVB nicht gefunden",
  "oiv.error.forbidden": "Sie haben keine Berechtigung, um auf diese Ressource zuzugreifen",
  "oiv.error.general": "Entschuldigung, etwas ist schiefgegangen",
  "oiv.error.indexInfoNotFound": "Suchindex nicht gefunden",
  "oiv.error.invalidFeedbackCategory": "Failed to send the feedback",
  "oiv.feedback.category.no_answer_received": "Beantwortet nicht die Frage",
  "oiv.feedback.category.objectively_incorrect": "Sachlich nicht korrekt",
  "oiv.feedback.category.other_reason": "Anderer Grund",
  "oiv.feedback.category.style_not_good": "Stil gefällt mir nicht",
  "oiv.feedback.category.too_comprehensive": "Zu umfassend",
  "oiv.feedback.input.placeholder": "Zusätzliche Details",
  "oiv.feedback.introduction": "Ihre Rückmeldung hilft uns, das Tool zu verbessern.",
  "oiv.feedback.send.fail": "Rückmeldung senden fehlgeschlagen",
  "oiv.feedback.send.success": "Feedback erfolgreich gesendet",
  "oiv.feedback.title": "Feedback",
  "oiv.footer.company.name": "Protekta Rechtsschutz-Versicherung AG",
  "oiv.footer.contactAndFeedback": "Services & Kontakt",
  "oiv.footer.contactAndFeedback.link": "https://www.mobiliar.ch/kontakt",
  "oiv.footer.dataProtection": "Datenschutz",
  "oiv.footer.dataprotection.link": "https://www.mobiliar.ch/datenschutz",
  "oiv.footer.imprint": "Impressum",
  "oiv.footer.imprint.link": "https://www.mobiliar.ch/impressum",
  "oiv.footer.instruction": "Nutzungshinweise",
  "oiv.footer.instruction.p1": "Bitte beachten Sie, dass die Darstellung der digitalen Allgemeinen Bedingungen auf verschiedenen Endgeräten und Browsern unterschiedlich sein kann.",
  "oiv.footer.instruction.p2": "Wir empfehlen Ihnen, die PDF-Version der Allgemeinen Bedingungen in der jeweiligen Sprache herunterzuladen und auf Ihrem Gerät zu speichern, um sicherzustellen, dass Sie die rechtsverbindliche Version zur Verfügung haben.",
  "oiv.footer.instruction.p3": "Sollten aufgrund von Fehlern oder Missverständnissen, die aufgrund der HTML-Darstellung entstehen können, Fragen oder Unklarheiten auftreten, empfehlen wir Ihnen, sich auf die rechtsverbindliche PDF-Version der Allgemeinen Bedingungen zu beziehen.",
  "oiv.footer.legalInfo": "Rechtliche Hinweise",
  "oiv.footer.legalInfo.link": "https://www.mobiliar.ch/rechtliches",
  "oiv.homepage.button.question": "((not in use)) Fragen",
  "oiv.homepage.head.title": "Kundeninformationen und Allgemeine Bedingungen",
  "oiv.homepage.info.paragraph.b2c": "Haben Sie Fragen zu Ihrer Versicherung? Die Allgemeinen Versicherungsbedingungen erlauben ihnen die wichtigsten Informationen einzusehen.",
  "oiv.homepage.info.paragraph.b2e": "Haben Sie Fragen zu Ihrer Versicherung? Die Allgemeinen Versicherungsbedingungen erlauben ihnen die wichtigsten Informationen einzusehen.",
  "oiv.homepage.notFeelLikeWriting": "((not in use)) Keine Lust zum Schreiben?",
  "oiv.homepage.p.goToTopic": "((not in use)) Kein Problem! Steigen Sie direkt in ein Thema ein.",
  "oiv.homepage.placeholder.textarea": "((not in use)) Beispielfragestellung, welche die Idee vermitteln...",
  "oiv.homepage.title.content": "Inhalt",
  "oiv.indexSearch.error.text": "Bitte versuche Sie es in ein paar Minuten erneut.",
  "oiv.indexSearch.error.title": "Entschuldigung, etwas ist schiefgegangen",
  "oiv.indexSearch.results": "Resultate für",
  "oiv.indexSearch.title": "Suche",
  "oiv.message.copy": "Kopiert!",
  "oiv.message.urlCopy": "URL kopiert!",
  "oiv.navigation.splitButton.menu": "Inhalt",
  "oiv.navigation.splitButton.qa": "Q&A",
  "oiv.navigation.splitButton.search": "Suche",
  "oiv.navigation.title.contact": "Kontakt",
  "oiv.navigation.title.contact.link": "https://www.mobiliar.ch/kontakt",
  "oiv.navigation.title.downloadPDF": "PDF herunterladen",
  "oiv.navigation.title.furtherTopics": "Weiterführende Themen",
  "oiv.navigation.title.goToCustomerPortal": "Zum Kundenportal",
  "oiv.navigation.title.goToCustomerPortal.link": "https://www.mobiliar.ch/login",
  "oiv.navigation.title.requestAdvice": "Hilfe anfordern",
  "oiv.navigation.title.visitProductPage": "Produktseite",
  "oiv.no-permission.title": "Sie haben keinen Zugriff auf diese Seite.\nFalls sie den Zugriff benötigen, bestellen Sie bitte die Rolle \"OIV Approval\".",
  "oiv.not-available.title": "Das Veröffentlichungsdatum für diesen Inhalt wurde noch nicht erreicht.",
  "oiv.not-found.avb-overview": "Übersicht: Allgemeine Bedingungen (www.mobiliar.ch)",
  "oiv.not-found.avb-overview-rednet": "E-Bibliothek: Allgemeine Versicherungsbedingungen",
  "oiv.not-found.avb-overview-rednet.link": "https://services.marketing.mobiliar.ch/de/downloads/e-bibliothek?list.filter=%7B%22mobi:mawi.productgroup%22:%5B%22root.avb.%22%5D,%22censhare:asset.language%22:%5B%22de%22%5D%7D&s.text=&m.fset=true",
  "oiv.not-found.avb-overview.link": "https://mobiliar.ch/avb",
  "oiv.not-found.contact": "Kontakt",
  "oiv.not-found.contact.link": "https://www.mobiliar.ch/kontakt",
  "oiv.not-found.continue": "Weiter",
  "oiv.not-found.customer-portal": "«Meine Mobiliar» Kundenportal",
  "oiv.not-found.customer-portal.link": "https://www.mobiliar.ch/login",
  "oiv.not-found.heading.text": "Die angeforderte Seite konnten wir nicht finden. Bitte probieren Sie eine der folgenden Optionen, damit Sie Ihr Ziel erreichen können.",
  "oiv.not-found.heading.title": "Wir tappen leider im Dunkeln.",
  "oiv.not-found.more-possibilities": "Weitere Einstiegsmöglichkeiten",
  "oiv.not-found.protekta.avb-overview": "Übersicht: Allgemeine Bedingungen (www.protekta.ch)",
  "oiv.placeholder.search": "Was suchen Sie?",
  "oiv.search.error.message": "Bereit zu suchen? Bitte geben Sie Ihren Suchbegriff ein.",
  "oiv.title.collapseAll": "Alle zusammenklappen",
  "oiv.title.expandAll": "Alle ausklappen"
}