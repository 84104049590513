{
  "chat_with_avb_failed.generic_error": "La fonction Q&A n’est pas disponible",
  "chat_with_avb_failed.index_not_found": "La fonction Q&A n’est pas disponible",
  "chat_with_avb_failed.rate_limit_exceeded": "La fonction Q&A n’est pas disponible",
  "chat_with_avb_failed.service_request_error": "La fonction Q&A n’est pas disponible",
  "chat_with_avb_failed.value_error.invalid_language_or_text_reference": "La fonction Q&A n’est pas disponible",
  "oiv.aiSearch.console.createNewConversation": "Créer une nouvelle conversation",
  "oiv.aiSearch.console.firstQuestion": "Saisir une nouvelle question",
  "oiv.aiSearch.console.followUpQuestion": "Saisir une question subséquente",
  "oiv.aiSearch.console.sendMessage": "Envoyer",
  "oiv.aiSearch.disclaimer": "Les réponses aux questions sont générées à l’aide d’une intelligence artificielle. Elles n’ont pas de valeur juridique. Aucune donnée personnelle ne doit être saisie dans les questions.",
  "oiv.aiSearch.filter.chosenTopic": "Chapitre sélectionné",
  "oiv.aiSearch.filter.selectAllTopics": "Tous les chapitres",
  "oiv.aiSearch.filter.selectTopic": "Sélectionner un chapitre",
  "oiv.aiSearch.followUpQuestionSeparator": "Question subséquente",
  "oiv.aiSearch.mobiAi": "Mobi-AI",
  "oiv.aiSearch.newConversationSeparator": "Nouvelle question",
  "oiv.aiSearch.question": "Question",
  "oiv.aiSearch.reference": "Sources",
  "oiv.aiSearch.Sie": "Vous",
  "oiv.aiSearch.suggestion": "Suggestions",
  "oiv.aiSearch.suggestion.bird": "[Not used anymore] Les dommages causés au bâtiment par les fientes d’oiseaux sont-ils assurés?",
  "oiv.aiSearch.suggestion.blockedWaterPipes": "[Not used anymore] Le nettoyage des canalisations bouchées est-il assuré?",
  "oiv.aiSearch.suggestion.liability": "[Not used anymore] Jusqu’à quelle somme de construction la responsabilité civile du maître d’ouvrage est-elle couverte?",
  "oiv.aiSearch.suggestion.lossOfRentalIncome": "[Not used anymore] Les pertes de revenus locatifs dues au non-paiement des loyers sont-elles assurées?",
  "oiv.aiSearch.title": "Questions-réponses",
  "oiv.app.mobiliar": "la Mobilière",
  "oiv.app.protekta": "Protekta",
  "oiv.app.title": "Conditions générales",
  "oiv.button.close": "Fermer",
  "oiv.error.aiSearch.filterIsMissing.description": "Veuillez sélectionner un chapitre.",
  "oiv.error.aiSearch.filterIsMissing.title": "Aucun chapitre sélectionné",
  "oiv.error.aiSearch.notAvailable.description": "Veuillez réessayer dans quelques minutes ou contacter le Service Desk.",
  "oiv.error.aiSearch.notAvailable.title": "La fonction de questions-réponses n’est pas disponible.",
  "oiv.error.avbNotFound": "CGA non trouvées",
  "oiv.error.forbidden": "Vous n’avez pas l’autorisation d’accéder à cette ressource",
  "oiv.error.general": "Désolé, quelque chose n’a pas fonctionné",
  "oiv.error.indexInfoNotFound": "Index de recherche non trouvé",
  "oiv.error.invalidFeedbackCategory": "Failed to send the feedback",
  "oiv.feedback.category.no_answer_received": "Ne répond pas à ma question.",
  "oiv.feedback.category.objectively_incorrect": "Incorrect sur le plan factuel",
  "oiv.feedback.category.other_reason": "Autre motif",
  "oiv.feedback.category.style_not_good": "Le style ne me plaît pas.",
  "oiv.feedback.category.too_comprehensive": "Trop détaillé",
  "oiv.feedback.input.placeholder": "Détails supplémentaires",
  "oiv.feedback.introduction": "Vos commentaires nous aideront à améliorer l’outil.",
  "oiv.feedback.send.fail": "Échec de l’envoi du feed-back",
  "oiv.feedback.send.success": "Feed-back envoyé avec succès",
  "oiv.feedback.title": "Feed-back",
  "oiv.footer.company.name": "Protekta Assurance de protection juridique SA",
  "oiv.footer.contactAndFeedback": "Services et contact",
  "oiv.footer.contactAndFeedback.link": "https://www.mobiliere.ch/contact",
  "oiv.footer.dataProtection": "Protection des données",
  "oiv.footer.dataprotection.link": "https://www.mobiliere.ch/protection-des-donnees",
  "oiv.footer.imprint": "Impressum",
  "oiv.footer.imprint.link": "https://www.mobiliere.ch/impressum",
  "oiv.footer.instruction": "Conseils d’utilisation",
  "oiv.footer.instruction.p1": "Veuillez noter que l’affichage des Conditions générales numériques peut varier selon les terminaux et les navigateurs.",
  "oiv.footer.instruction.p2": "Nous vous recommandons de télécharger la version PDF des Conditions générales dans la langue souhaitée et de l’enregistrer sur votre appareil afin de vous assurer que vous disposez de la version juridiquement contraignante.",
  "oiv.footer.instruction.p3": "En cas de questions ou d’incertitudes résultant de malentendus ou d’erreurs dus à l’affichage HTML, nous vous recommandons de vous référer à la version PDF des Conditions générales qui fait foi.",
  "oiv.footer.legalInfo": "Mentions légales",
  "oiv.footer.legalInfo.link": "https://www.mobiliere.ch/aspect-juridique",
  "oiv.homepage.button.question": "((not in use)) Questions",
  "oiv.homepage.head.title": "Informations à la clientèle et Conditions générales",
  "oiv.homepage.info.paragraph.b2c": "Vous avez des questions sur votre assurance? Les Conditions générales d’assurance vous permettent de consulter les principales informations.",
  "oiv.homepage.info.paragraph.b2e": "Vous avez des questions sur votre assurance? Les Conditions générales d’assurance vous permettent de consulter les principales informations.",
  "oiv.homepage.notFeelLikeWriting": "((not in use)) Pas envie d’écrire?",
  "oiv.homepage.p.goToTopic": "((not in use)) Pas de problème! Sélectionnez directement un thème.",
  "oiv.homepage.placeholder.textarea": "((not in use)) Exemple de questions qui transmettent l’idée...",
  "oiv.homepage.title.content": "Contenu",
  "oiv.indexSearch.error.text": "Veuillez réessayer dans quelques minutes.",
  "oiv.indexSearch.error.title": "Désolé, quelque chose n’a pas fonctionné",
  "oiv.indexSearch.results": "Résultats pour",
  "oiv.indexSearch.title": "Recherche",
  "oiv.message.copy": "Copié!",
  "oiv.message.urlCopy": "URL copiée!",
  "oiv.navigation.splitButton.menu": "Contenu",
  "oiv.navigation.splitButton.qa": "Q&A",
  "oiv.navigation.splitButton.search": "Recherche",
  "oiv.navigation.title.contact": "Contact",
  "oiv.navigation.title.contact.link": "https://www.mobiliere.ch/contact",
  "oiv.navigation.title.downloadPDF": "Télécharger le PDF",
  "oiv.navigation.title.furtherTopics": "Thèmes complémentaires",
  "oiv.navigation.title.goToCustomerPortal": "Vers le portail clients",
  "oiv.navigation.title.goToCustomerPortal.link": "https://www.mobiliere.ch/login",
  "oiv.navigation.title.requestAdvice": "Demander de l’aide",
  "oiv.navigation.title.visitProductPage": "Page produit",
  "oiv.no-permission.title": "Vous n’avez pas accès à cette page.\nSi vous avez besoin d’y accéder, veuillez commander le rôle «OIV Approval».",
  "oiv.not-available.title": "La date de publication de ce contenu n’a pas encore été atteinte.",
  "oiv.not-found.avb-overview": "Vue d’ensemble: Conditions générales (www.mobiliere.ch)",
  "oiv.not-found.avb-overview-rednet": "Bibliothèque électronique: Conditions générales d’assurance",
  "oiv.not-found.avb-overview-rednet.link": "https://services.marketing.mobiliere.ch/fr/downloads/e-bibliotheque?m.fset=true&s.text=&list.filter=%7B%22mobi:mawi.productgroup%22:%5B%22root.avb.%22%5D,%22censhare:asset.language%22:%5B%22fr%22%5D%7D",
  "oiv.not-found.avb-overview.link": "https://www.mobiliere.ch/cga",
  "oiv.not-found.contact": "Contact",
  "oiv.not-found.contact.link": "https://www.mobiliere.ch/contact",
  "oiv.not-found.continue": "Continuer",
  "oiv.not-found.customer-portal": "Portail clients «Ma Mobilière»",
  "oiv.not-found.customer-portal.link": "https://www.mobiliere.ch/login",
  "oiv.not-found.heading.text": "Nous n’avons pas trouvé la page demandée. Veuillez essayer l’une des options suivantes pour atteindre votre destination.",
  "oiv.not-found.heading.title": "Nous avons fait chou blanc.",
  "oiv.not-found.more-possibilities": "Autres possibilités d’accès",
  "oiv.not-found.protekta.avb-overview": "Vue d’ensemble: Conditions générales (www.protekta.ch)",
  "oiv.placeholder.search": "Que cherchez-vous?",
  "oiv.search.error.message": "Prêt à chercher? Veuillez saisir votre mot-clé.",
  "oiv.title.collapseAll": "Replier tout",
  "oiv.title.expandAll": "Tout dévisser"
}