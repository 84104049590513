<div class="mobi-ui-ink-grey">
    <div
        class="oiv-expander mobi-ui-margin-t-xxl mobi-ui-padding-md mobi-ui-card-silent is-paddingless oiv-background-color-bg1"
        [id]="eId"
    >
        <mobi-ui-expander-silent
            class="mobi-ui-expander-silent is-inline"
            [title]="expanderTitle"
            [content]="expanderContent"
            [expanded]="expandedSingleState()"
            (closed)="onOpenedOrClosed('close')"
            (opened)="onOpenedOrClosed('open')"
            #expander
        ></mobi-ui-expander-silent>
    </div>
</div>
<ng-template #expanderTitle>
    @if (expanderLevel() === 0) {
        <h5 class="oiv-expander-header mobi-ui-text-left mobi-ui-ink-default">
            @if (orderNumber) {
                <span class="mobi-ui-margin-r-md"> {{ helper.removeLastDot(orderNumber, true) }}</span>
            }
            <span>{{ title }}</span>
        </h5>
    } @else if (expanderLevel() === 1) {
        <p class="mobi-ui-text-left mobi-ui-font-bold oiv-expander-title-sub-level">
            @if (orderNumber) {
                <span class="mobi-ui-margin-r-md"> {{ helper.removeLastDot(orderNumber, false) }}</span>
            }
            <span>{{ title }}</span>
        </p>
    } @else {
        <p class="mobi-ui-text-left oiv-expander-title-sub-level">
            @if (orderNumber) {
                <span class="mobi-ui-margin-r-md"> {{ helper.removeLastDot(orderNumber, false) }}</span>
            }
            <span>{{ title }}</span>
        </p>
    }
</ng-template>
<ng-template #expanderContent>
    <div class="oiv-expander-content" #zoneToCopy>
        @for (content of expanderContents; track $index) {
            @switch (content?.type) {
                @case (OIV_TYPE.INFO) {
                    <oiv-viewer-paragraph
                        [content]="content.content.toString()"
                        [cssClass]="getParagraphCss(content.type)"
                    />
                }
                @case (OIV_TYPE.PARA) {
                    <oiv-viewer-paragraph
                        [eId]="content.eId"
                        [content]="content.content.toString()"
                        [cssClass]="getParagraphCss(content.type)"
                    />
                }
                @case (OIV_TYPE.BOX) {
                    <oiv-viewer-box [contentArray]="helper.getContentAsArray(content)" [eId]="content.eId" />
                }
                @case (OIV_TYPE.BULLET_LIST) {
                    <oiv-viewer-bullet-list [items]="helper.getContentAsArray(content)" />
                }
                @case (OIV_TYPE.NUMBERED_LIST) {
                    <oiv-viewer-numbered-list
                        [items]="helper.getContentAsArray(content)"
                        [listType]="content?.attributeType ?? ''"
                    />
                }
                @case (OIV_TYPE.CONTAINER) {
                    <hr />
                    <oiv-viewer-expander
                        [expanderInput]="content"
                        [expanderLevel]="increaseExpanderLevel()"
                        [expandedAllState]="expandedAllState()"
                    />
                }
                @case (OIV_TYPE.TABLE) {
                    <oiv-viewer-table [tableContent]="content" />
                }
            }
        }
    </div>

    <div class="oiv-expander-button">
        <mobi-ui-icon-major
            class="mobi-ui-margin-l-xs"
            name="share"
            [mobiUiPopover]="shareConfirmation"
            (click)="share()"
            [title]="'oiv.message.urlCopy' | translate"
        >
        </mobi-ui-icon-major>
        <mobi-ui-icon-major
            class="mobi-ui-margin-l-xs"
            name="copy"
            (click)="copyContent(zoneToCopy)"
            [mobiUiPopover]="copyConfirmation"
            [title]="'oiv.message.copy' | translate"
        ></mobi-ui-icon-major>
    </div>

    <ng-template #shareConfirmation>
        <mobi-ui-popover-major>
            <span [innerHTML]="'oiv.message.urlCopy' | translate"></span>
        </mobi-ui-popover-major>
    </ng-template>

    <ng-template #copyConfirmation>
        <mobi-ui-popover-major>
            <span [innerHTML]="'oiv.message.copy' | translate"></span>
        </mobi-ui-popover-major>
    </ng-template>
</ng-template>
