@for (content of returnContentArray(content()); track content.eId) {
    @switch (content?.type) {
        @case (OIV_TYPE.PARA) {
            <oiv-viewer-paragraph
                [eId]="content.eId"
                [content]="content.content.toString()"
                [cssClass]="getParagraphCss(content.type)"
            />
        }
        @case (OIV_TYPE.NUMBERED_LIST) {
            <oiv-viewer-numbered-list
                [items]="helper.getContentAsArray(content)"
                [listType]="content?.attributeType ?? ''"
            />
        }
        @case (OIV_TYPE.BULLET_LIST) {
            <oiv-viewer-bullet-list [items]="helper.getContentAsArray(content)" />
        }
    }
}
