<div class="mobi-ui-wrapper">
    <oiv-viewer-header
        #header
        (iconClick)="onHeaderIconClick($event)"
        (logoClick)="backToHomePage()"
        [isDisplayIcon]="isDisplayIcon()"
    />
    <div class="main-content">
        <router-outlet />
    </div>
    <oiv-viewer-footer [links]="links()" (languageChange)="updateLanguage($event)" />
</div>
