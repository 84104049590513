import { Component, computed, inject, input } from '@angular/core';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { getParagraphCss } from '../../../util/get-css-style';
import { Helper } from '../../helper';
import { RenderContentComponent } from '../render-content/render-content.component';

@Component({
    selector: 'oiv-viewer-table',
    standalone: true,
    imports: [RenderContentComponent],
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
})
export class TableComponent {
    helper = inject(Helper);

    tableContent = input({} as Content);

    headerRowCount = computed(() => this.tableContent().tableSpec?.attributeHeaderRowCount || 0);
    footerRowCount = computed(() => this.tableContent().tableSpec?.attributeFooterRowCount || 0);
    bodyRowCount = computed(
        () => this.tableContent().content?.length - this.headerRowCount() - this.footerRowCount() || 0,
    );

    tableHeader = computed(() => (this.tableContent().content?.slice(0, this.headerRowCount()) as Content[]) || []);
    tableBody = computed(
        () =>
            (this.tableContent().content?.slice(
                this.headerRowCount(),
                this.headerRowCount() + this.bodyRowCount(),
            ) as Content[]) || [],
    );
    tableFooter = computed(
        () => (this.tableContent().content?.slice(this.headerRowCount() + this.bodyRowCount()) as Content[]) || [],
    );

    getRowSpan(content: Content) {
        return content.tableSpec?.attributeRowspan || 0;
    }

    getColSpan(content: Content) {
        return content.tableSpec?.attributeColspan || 0;
    }

    returnContentArray(content: Content | string): Content[] {
        return typeof content === 'string' ? [] : (content.content as Content[]);
    }

    getRowSize(index: number) {
        const width = this.tableContent()?.tableSpec?.tableColSpec?.[index].width;
        return width
            ? Number(width)
            : Array.isArray(this.tableContent().content)
              ? 100 / this.tableContent().content.length
              : 0;
    }

    protected readonly OIV_TYPE = OIV_TYPE;
    protected readonly getParagraphCss = getParagraphCss;
}
