import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';

import { filter, of, switchMap, tap } from 'rxjs';

import { Store } from '@ngrx/store';

import { concatLatestFrom } from '@ngrx/operators';

import { Router } from '@angular/router';

import {
    Content,
    oivCoreSharedActions,
    oivCoreSharedFeature,
    PAGE_URL_PATH,
    TealiumTrackingFacade,
    MOBILIAR,
    PROTEKTA,
    AppSourceUrlMap,
} from '@mobi/oiv-viewer-utils-ng-jslib';

import { OivXmlParserService } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { Helper } from '../../../../share/helper';

import { OivViewerService } from '../service/oiv-viewer.service';

import { filterTruthy } from '../../../../util/filter-truthy';

import { oivSystemSettingActions, oivViewerActions, oivViewerApprovalActions } from './oiv-viewer.actions';
import { fromOivViewer } from './oiv-viewer.selectors';

@Injectable()
export class OivViewerEffects {
    #store = inject(Store);

    #actions$ = inject(Actions);
    #oiXmlParserService = inject(OivXmlParserService);
    #oivViewerService = inject(OivViewerService);
    #helper = inject(Helper);
    #router = inject(Router);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #appContext = this.#store.selectSignal(oivCoreSharedFeature.selectContext);
    #appSource = this.#store.selectSignal(oivCoreSharedFeature.selectSource);

    $languageChanged = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivCoreSharedActions.changeLanguage),
            filter(
                () =>
                    !this.#router.url.includes(PAGE_URL_PATH.NOT_FOUND) &&
                    !this.#router.url.includes(PAGE_URL_PATH.NOT_AVAILABLE) &&
                    !this.#router.url.includes(PAGE_URL_PATH.NO_PERMISSION),
            ),
            concatLatestFrom(() => [
                this.#store.select(fromOivViewer.selectUrlName),
                this.#store.select(oivCoreSharedFeature.selectIsApprovalMode),
            ]),
            switchMap(([, urlName, isApproval]) => {
                if (isApproval) {
                    return of(oivViewerApprovalActions.load({ urlName }));
                }
                return of(oivViewerActions.load({ urlName }));
            }),
        ),
    );

    load$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivViewerActions.load, oivViewerApprovalActions.load),
            concatLatestFrom(() => this.#store.select(oivCoreSharedFeature.selectLanguage)),
            switchMap(([{ type, urlName }, language]) => {
                let isApprovalMode = false;
                if (type === oivViewerApprovalActions.load.type) {
                    isApprovalMode = true;
                }
                return this.#oivViewerService.getXml(urlName, language, isApprovalMode).pipe(
                    map(avb => {
                        // Redirect if wrong product of current sourceType
                        // Check if Protekta based on Product Art
                        if (this.#appSource() === MOBILIAR && (avb.productArt === 20003 || avb.productArt === 20521)) {
                            this.#store.dispatch(oivViewerActions.redirectToAppSource({ sourceType: PROTEKTA }));
                            return null;
                        }
                        if (this.#appSource() === PROTEKTA && avb.productArt !== 20003 && avb.productArt !== 20521) {
                            this.#store.dispatch(oivViewerActions.redirectToAppSource({ sourceType: MOBILIAR }));
                            return null;
                        }
                        return avb;
                    }),
                    filterTruthy,
                    tap(({ productArt }) => {
                        productArt && this.#store.dispatch(oivViewerActions.loadFeatures({ productArt }));
                    }),
                    map(avb => {
                        let avbChapters: Content[] = [];
                        let avbCoverSheet: Content = {} as Content;
                        if (avb.xmlContent) {
                            this.#oiXmlParserService.parseOivXml(avb.xmlContent);
                            const oivTree = this.#oiXmlParserService.getAllChapters();
                            const mappingContents = this.#helper.mappingToContents(oivTree);
                            avbChapters = this.#helper.getChapters(mappingContents);
                            avbCoverSheet = this.#helper.getCoverSheet(mappingContents);
                        }
                        // Remove the xmlContent from the avb object because we parse it into the avbChapters
                        avb.xmlContent = '';

                        this.#tealiumTrackingFacade.addVolatile('language_code', language);
                        this.#tealiumTrackingFacade.addVolatile('user_loginStatus', 'true');

                        return oivViewerActions.loaded({
                            avbMetadata: avb,
                            avbChapters,
                            avbCoverSheet,
                        });
                    }),
                    catchError(error => {
                        return of(oivViewerActions.loadFailure({ error }));
                    }),
                );
            }),
        ),
    );

    loaded$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivViewerActions.loaded),
            concatLatestFrom(() => this.#store.select(fromOivViewer.selectAvbMetaData)),
            switchMap(([, avb]) => {
                return of(
                    oivViewerActions.loadIndex({
                        textReference: avb.textReference,
                        assetType: avb.assetType || '',
                        contentVersion: avb.contentVersion,
                    }),
                );
            }),
        ),
    );

    redirectToApp$ = createEffect(
        () =>
            this.#actions$.pipe(
                ofType(oivViewerActions.redirectToAppSource),
                tap(({ sourceType }) => {
                    const isProduct = (window.document.defaultView as Window).MOBI_RWC_CONFIG?.stage === 'prod';
                    // B2E | B2C
                    const appContext = this.#appContext();
                    const baseUrl = isProduct
                        ? AppSourceUrlMap[sourceType][appContext].product
                        : AppSourceUrlMap[sourceType][appContext].preproduct;
                    window.location.href = baseUrl + this.#router.url;
                }),
            ),
        { dispatch: false },
    );

    loadFailed$ = createEffect(
        () =>
            this.#actions$.pipe(
                ofType(oivViewerActions.loadFailure),
                concatLatestFrom(() => this.#store.select(oivCoreSharedFeature.selectLanguage)),
                tap(([error, language]) => {
                    this.#store.dispatch(oivViewerActions.refresh());
                    if (error.error.status === 406) {
                        if (this.#appContext() === 'B2C') {
                            this.#router.navigate([`/${PAGE_URL_PATH.NOT_AVAILABLE}`]);
                        } else {
                            this.#router.navigate([`/${language}/${PAGE_URL_PATH.NOT_AVAILABLE}`]);
                        }
                    } else {
                        if (this.#appContext() === 'B2C') {
                            this.#router.navigate([`/${PAGE_URL_PATH.NOT_FOUND}`]);
                        } else {
                            this.#router.navigate([`/${language}/${PAGE_URL_PATH.NOT_FOUND}`]);
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    $loadIndex = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivViewerActions.loadIndex),
            concatLatestFrom(() => this.#store.select(oivCoreSharedFeature.selectLanguage)),
            switchMap(([{ textReference, assetType, contentVersion }, language]) => {
                return this.#oivViewerService.getIndices(textReference, language, assetType, contentVersion).pipe(
                    map(index => {
                        return oivViewerActions.loadedIndex({ index });
                    }),
                    catchError(error => {
                        return of(oivViewerActions.loadIndexFailure({ error }));
                    }),
                );
            }),
        ),
    );

    $loadFeedbackCategories = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivSystemSettingActions.loadFeedbackCategories),
            switchMap(() => {
                return this.#oivViewerService.getCategories().pipe(
                    map(categories => {
                        return oivSystemSettingActions.loadedFeedbackCategories({ categories });
                    }),
                    catchError(error => {
                        return of(oivSystemSettingActions.loadFeedbackCategoriesFailure({ error }));
                    }),
                );
            }),
        ),
    );

    $loadToggleFeatures = createEffect(() =>
        this.#actions$.pipe(
            ofType(oivViewerActions.loadFeatures),
            switchMap(({ productArt }) => {
                return this.#oivViewerService.getToggleFeatures(productArt).pipe(
                    map(features => {
                        return oivViewerActions.loadedFeatures({ features });
                    }),
                    catchError(error => {
                        return of(oivViewerActions.loadToggleFeaturesFailure({ error }));
                    }),
                );
            }),
        ),
    );
}
